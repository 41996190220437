<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:headerTitle="detailTitle"
		tooltipMsg="manualforcontac3301"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	>
		<template #beforeForm>
			<remark
			v-if="detailEntityId"
			:entityId="detailEntityId"
			:backmethod="redirectToTable"
			:entityTypeId="remarkEntityType"
			:remarkTypeId="null"
			:administratorList="administratorList"
		></remark>

			<email-send-dropdown
				:labelText="$t('e-mails:')"
				:pesLogTypeId="pesLogTypeId"
				:buttonText="$t(`fornewyear'sev`)"
				:offersList="offersList"
				:entityId="detailEntityId"
				:emailExist="model.EmailExists"
				:withTable="true"
			></email-send-dropdown>
		</template>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import serviceDictionary from '@/services/service/dictionary.service';
import serviceEnums from '@/services/service/enums.service';
import serviceCommon from '../../services/service/common.service';
import enums from '@/services/helpers/enums';
import apiUser from '@/services/api/user.api';
import servicePortalEmail from '@/modules/portal-emails/portal-email.service';
import EmailSendDropdown from '@/modules/emails/email-send.dropdown';

import service from './contact.service';
import { model, fields } from './contact.form-data';

export default {
	components: {
		DetailPage,
		EmailSendDropdown,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'contact',
			routeParam: 'ContactId',
			model,
			schema: {
				groups: fields,
			},
			remarkEntityType: enums.remarkEntityType.Contact,
			pesLogTypeId: enums.sendLogsTypeEnum.Contact,
			administratorList: [],
			offersList: [],
		};
	},

	computed: {
		detailTitle() {
			return (
				this.$i18n.t('yes2185') +
				' ' +
				this.$i18n.t('id') +
				' ' +
				this.model.ContactId +
				(this.model.UserId
					? ', P' +
					  this.model.UserId +
					  ' (' +
					  this.$i18n.t('registered') +
					  ' ' +
					  moment.utc(this.model.UserCreatedOn).format(this.$store.state.lang.DateTimeFormat) +
					  ')'
					: '')
			);
		},
	},

	async created() {
		const [administratorList, offersList] = await Promise.all([this.getAdministrators(), this.getEmailSubjects()]);

		this.administratorList = administratorList;
		this.offersList = offersList;
	},

	methods: {
		async loadResources() {
			const [salutationEnum, objectTypeEnum, registrationRecommends] = await Promise.all([
				service.getSalutationEnum(this.$i18n, this.$store),
				service.getObjectTypeEnum(this.$store),
				serviceDictionary.getRegistrationRecommends(),
			]);
			const CountryValues = this.$store.state.country.data;

			this.updateSchemaFields({
				CountryId: {
					values: CountryValues,
				},
				LanguageCode: {
					values: CountryValues,
				},
				LanguageCodeUser: {
					values: CountryValues,
				},
				ContactSalutationId: {
					dataval: salutationEnum.selector.filter((x) => x.value),
				},
				CotypeId: {
					dataval: objectTypeEnum.responseSelector.filter((x) => x.value != 1),
				},
				ContactOperation: {
					dataval: serviceEnums.getEnumForDropdown('operationSeason'),
				},
				ContactType: {
					dataval: serviceEnums.getEnumForDropdown('contactType'),
				},
				RoleId: {
					values: serviceEnums.getEnumForVFG('memberRole'),
				},
				RecommendId: {
					values: registrationRecommends.registrationRecommendSelectorItemsFormGenerator,
				},
				ContactEmailLogin: {
					contactId: this.detailEntityId,
				},
				ContactEmails: {
					contactId: this.detailEntityId,
				},
				_password: {
					contactId: this.detailEntityId,
				},
			});
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.ContactId, this.$modal, this.deleteModel);
		},

		async getAdministrators() {
			const administratorList = await apiUser.getAdministratorList().then((res) => res.data);
			administratorList.unshift({ id: null, name: this.$i18n.t('unassigned') });
			return administratorList;
		},

		async getEmailSubjects() {
			const emailIds = '61,62';
			return servicePortalEmail.getEmailSubjectsByIds(emailIds).then((resp) => resp.data);
		},
	},
};
</script>

<style lang="scss">
.subcribe-history,
.subcribe-history-visitor {
	margin-bottom: 0px;
}
</style>
