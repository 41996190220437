import i18n from '../../services/lang';
import api from './contact.api';
import validatorCommon from '../../services/helpers/validator.common';

const validators = {
	validateSurnameAndSalutation() {
		if (
			(this.model.ContactName.isNullOrWhiteSpace() == false || this.model.ContactSurname.isNullOrWhiteSpace() == false) &&
			!this.model.ContactSalutationId
		) {
			return [i18n.t('amistake!')];
		} else {
			return [];
		}
	},

	emailOk(value, model) {
		return new Promise((resolve, reject) => {
			var error = validators.checkEmail(value, model.required);
			if (error) {
				resolve(error);
			} else {
				api.checkExistsEmail({ contactId: model.contactId, email: value }).then((resp) => {
					if (!resp.data) resolve();
					else resolve([i18n.t('amistake!')]);
				});
			}
		});
	},

	multipleEmailsOk(value, model) {
		if (value) {
			var emailsArray = value.split('\n');
			return new Promise((resolve, reject) => {
				for (let email of emailsArray) {
					var error = validators.checkEmail(email, model.required);
					if (error)
					{
						resolve(error);
						return;
					}
				};

				document.body.classList.add('wait');
				api.checkExistsMultipleEmails({ contactId: model.contactId, emails: value }).then((resp) => {
					document.body.classList.remove('wait');
					if (!resp.data) {
						resolve();
					} else {
						resolve([i18n.t('amistake!')]);
					}
				});
			});
		}
	},

	checkEmail(value, required) {
		let res = validatorCommon.checkEmpty(value, required);

		if (res) return res;

		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
		if (!re.test(value)) {
			return [i18n.t('amistake!')];
		}

		return null;
	},
};

export default validators;
