var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("detail-page", {
        ref: _vm.detailPageRefName,
        attrs: {
          formModel: _vm.model,
          formSchema: _vm.schema,
          headerTitle: _vm.detailTitle,
          tooltipMsg: "manualforcontac3301",
        },
        on: {
          saveForm: function ($event) {
            return _vm.saveModel($event)
          },
          closeDetail: function ($event) {
            return _vm.redirectToTable()
          },
          delete: function ($event) {
            return _vm.openDeleteModal()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "beforeForm",
              fn: function () {
                return [
                  _vm.detailEntityId
                    ? _c("remark", {
                        attrs: {
                          entityId: _vm.detailEntityId,
                          backmethod: _vm.redirectToTable,
                          entityTypeId: _vm.remarkEntityType,
                          remarkTypeId: null,
                          administratorList: _vm.administratorList,
                        },
                      })
                    : _vm._e(),
                  _c("email-send-dropdown", {
                    attrs: {
                      labelText: _vm.$t("e-mails:"),
                      pesLogTypeId: _vm.pesLogTypeId,
                      buttonText: _vm.$t("fornewyear'sev"),
                      offersList: _vm.offersList,
                      entityId: _vm.detailEntityId,
                      emailExist: _vm.model.EmailExists,
                      withTable: true,
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2458813696
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }