import vueFormGenerator from 'vue-form-generator';
import auth from '@/services/auth.service';
import enums from '@/services/helpers/enums';
import validators from './contact.validators';
import service from './contact.service';

export const model = {
	ContactId: 0,
	ContactTitle: '',
	ContactName: '',
	ContactSurname: '',
	ContactCompany: '',
	ContactSalutationId: null,
	CityId: 0,
	RegionId: 0,
	ContactNote: '',
	ContactEmailLogin: '',
	ContactEmails: '',
	ContactPhones: '',
	ContactWebs: '',
	ContactObjectName: '',
	CotypeId: 0,
	ContactIsVerified: 1,
	ContactType: 1,
	ContactIsSubscribed: true,
	ContactIsSubscribedChangedOn: null,
	ContactIsSubscribedVisitor: true,
	ContactIsSubscribedVisitorChangedOn: null,
	ContactIsSubscribedImportantNews: false,
	ContactDateMailboxIsFull: null,
	ContactIsWrong: 0,
	ContactIsBanned: 0,
	CgroupId: 1,
	ContactOperation: 1,
	ContactNoteUnsubscribe: '',
	ContactIsBadEmail: 0,
	ContactIsTotalMagor: 0,
	ContactRemarkDatetime: null,
	ContactRemarkText: null,
	LanguageCode: null,
	CountryId: null,
	CreatedOn: null,
	UserCreatedOn: null,
	ContactSalutationEmailId: null,
	ContactSurnameEmail: '',
	ContactSubscribeDemand: 0,
	UserId: null,
	EmailExists: false,
	SubsribeHistoryList: [],
	SubsribeHistoryListVisitor: [],
	SubsribeHistoryListImportantNews:[],
	LanguageCodeUser: 'sk',
	RoleId: 0,
	RecommendId: 0,
};

export const fields = [
	{
		fields: [
			{
				type: 'select',
				model: 'CountryId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					value: 'Id',
					name: 'Name',
				},
				i18n: {
					label: 'country:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
			},
			{
				type: 'select',
				model: 'LanguageCode',
				required: true,
				validator: vueFormGenerator.validators.string,
				values: [],
				selectOptions: {
					value: 'LanguageCode',
					name: 'LanguageCode',
				},
				i18n: {
					label: 'languageofconta',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
			},
			{
				type: 'select',
				model: 'LanguageCodeUser',
				required: true,
				values: [],
				selectOptions: {
					value: 'LanguageCode',
					name: 'LanguageCode',
				},
				i18n: {
					label: 'languageofuseri',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
				visible(model) {
					return model && +model.ContactType === 2;
				},
			},
			{
				type: 'radios-group',
				groupname: '1',
				model: 'ContactSalutationId',
				required: true,
				validator: validators.validateSurnameAndSalutation,
				dataval: [],
				i18n: {
					label: 'no2186',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ContactTitle',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'title:',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ContactName',
				validator: [vueFormGenerator.validators.string, validators.validateSurnameAndSalutation],
				i18n: {
					label: 'name:',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ContactSurname',
				validator: [vueFormGenerator.validators.string, validators.validateSurnameAndSalutation],
				i18n: {
					label: 'surname:',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ContactSurnameEmail',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'surname(fore-ma',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ContactEmailLogin',
				validator: validators.emailOk,
				contactId: null,
				i18n: {
					label: 'logine-mail:',
					help: 'e-mail,whichhas',
				},
				visible(model) {
					return model && +model.ContactType === 2;
				},
			},
			{
				type: 'change-password',
				model: '_password',
				validator: validators.required,
				contactId: null,
				i18n: {
					label: 'password',
				},
				visible(model) {
					return model && +model.ContactType === 2;
				},
			},
			{
				type: 'textArea',
				rows: 4,
				model: 'ContactEmails',
				validator: validators.multipleEmailsOk,
				validateDebounceTime: 1000,
				min: 1,
				contactId: null,
				i18n: {
					label: 'e-mails:',
					help: 'listofe-mailsre',
				},
				get: function(model) {
					return model && model.ContactEmails ? service.itemsOnNewLine(model.ContactEmails) : '';
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'UserPhones',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'telephonelogin',
				},
				visible(model) {
					return model && +model.ContactType === 2;
				},
			},
			{
				type: 'textArea',
				rows: 4,
				model: 'ContactPhones',
				min: 1,
				i18n: {
					label: 'telephonenumber',
				},
				get: function(model) {
					return model && model.ContactPhones ? service.itemsOnNewLine(model.ContactPhones) : '';
				},
			},
			{
				type: 'textArea',
				rows: 4,
				model: 'ContactWebs',
				min: 1,
				i18n: {
					label: 'website:',
				},
				get: function(model) {
					return model && model.ContactWebs ? service.itemsOnNewLine(model.ContactWebs) : '';
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ContactObjectName',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'objectname:',
				},
			},
			{
				type: 'autocomplete',
				typeSearch: '1',
				model: 'CityId',
				validator: vueFormGenerator.validators.integer,
				i18n: {
					label: 'city:',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'radios-group',
				groupname: '2',
				model: 'CotypeId',
				validator: validators.required,
				dataval: [],
				i18n: {
					label: 'objecttype:',
				},
			},
			{
				type: 'radios-group',
				groupname: '3',
				model: 'ContactOperation',
				validator: validators.required,
				dataval: [],
				i18n: {
					label: 'inworkingorder:',
				},
			},
			{
				type: 'radios-group',
				groupname: '4',
				model: 'ContactType',
				required: true,
				dataval: [],
				i18n: {
					label: 'typeofcontact:',
				},
			},
			{
				type: 'select',
				model: 'RoleId',
				values: [],
				selectOptions: {
					noneSelectedText: '',
				},
				i18n: {
					label: 'role',
					help: 'youcanchoosethe',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
				visible(model) {
					return model && +model.ContactType === 2;
				},
				disabled: !auth.IsAllowedForUser(enums.role.Admin),
			},
			{
				type: 'switch',
				model: 'ContactSubscribeDemand',
				i18n: {
					label: 'iwanttoreceivea',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
				styleClasses: 'subcribe-demand',
				visible(model) {
					return model && +model.ContactType === 2;
				},
			},
			{
				type: 'switch',
				model: 'ContactIsSubscribed',
				i18n: {
					label: 'iwanttoreceivem',
					help: 'allowedsendingo',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
				styleClasses: 'subcribe-member',
				visible(model) {
					return model && +model.ContactType !== 3;
				},
			},
			{
				type: 'subscribeHistory',
				model: 'SubsribeHistoryList',
				i18n: {
					label: ' ',
				},
				styleClasses: 'subcribe-history',
				visible(model) {
					return model && model.SubsribeHistoryList.length != 0;
				},
			},
			
			{
				type: 'switch',
				model: 'ContactIsSubscribedImportantNews',
				i18n: {
					label: 'iwanttoreceivei3567',
					help: 'pallowedsending',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
				styleClasses: 'subcribe-member',
				visible(model) {
					return model && +model.ContactType == 2;
				},
			},
			{
				type: 'subscribeHistory',
				model: 'SubsribeHistoryListImportantNews',
				i18n: {
					label: ' ',
				},
				styleClasses: 'subcribe-history',
				visible(model) {
					return model && model.SubsribeHistoryListImportantNews.length != 0;
				},
			},

			{
				type: 'switch',
				model: 'ContactIsSubscribedVisitor',
				i18n: {
					label: 'iwanttoreceivem2293',
					help: 'activation/deac',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
				styleClasses: 'subcribe-visitor',
			},
			{
				type: 'subscribeHistory',
				model: 'SubsribeHistoryListVisitor',
				i18n: {
					label: ' ',
				},
				styleClasses: 'subcribe-history-visitor',
				visible(model) {
					return model && +model.SubsribeHistoryListVisitor.length != 0;
				},
			},
			{
				type: 'switch',
				model: 'ContactIsVerified',
				i18n: {
					label: 'checked:',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'single-datepicker',
				showtime: false,
				autoselect: true,
				language: 'sk',
				model: 'ContactDateMailboxIsFull',
				validator: vueFormGenerator.validators.date,
				i18n: {
					label: 'mailboxoverfill',
					help: 'thecontactdoesn',
				},
			},
			{
				type: 'switch',
				model: 'ContactIsBadEmail',
				i18n: {
					label: 'wronge-mail:',
					help: 'thecontactisinc',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'ContactIsTotalMagor',
				i18n: {
					label: "hedoesn'twantt",
					help: 'acontactwhohasr',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'ContactIsWrong',
				i18n: {
					label: 'henolongerhasac',
					help: 'acontactwhohass',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'select',
				model: 'RecommendId',
				values: [],
				selectOptions: {},
				i18n: {
					label: 'recommendationf',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
				visible(model) {
					return model && +model.ContactType === 2;
				},
			},
			{
				type: 'textArea',
				rows: 1,
				model: 'RecommendValue',
				min: 1,
				i18n: {
					label: ' ',
				},
				visible(model) {
					return model && (+model.RecommendId === 3 || +model.RecommendId === 4);
				},
			},
			{
				type: 'textArea',
				rows: 8,
				model: 'ContactNote',
				min: 1,
				i18n: {
					label: 'remark:',
				},
			},
		],
	},
];
