import http from '../../services/helpers/http';

const api = {
	getList(model) {
		return http.getWithFilters('Contact/GetPaginatedListContacts', model);
	},

	checkExistsEmail(model) {
		return http.get('Contact/CheckExistsEmail', model);
	},

	checkExistsMultipleEmails(model) {
		return http.get('Contact/CheckExistsMultipleEmails', model);
	},
};

export default api;
